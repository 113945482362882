import React from 'react'

function Moods(props) {
  return (
    <div className='text-4xl border-solid shadow '>
        {props.symbol}
    </div>
  )
}

export default Moods