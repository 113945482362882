import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from '@mui/material';

function Navigation() {
  return (
          <div className='mr-10'>{/*Lets have this appear when something is typed into the text field */}
            <Link href="/select" >
            <ArrowForwardIcon />
            </Link>
            </div>
         
  )
}

export default Navigation