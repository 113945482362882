import { Button } from '@mui/material';
import React, { useState } from 'react'

function Quant(props) {
  const [quantity, setQuantity] = useState(0);

  return (
    <div className='space-y-5'>
         <div className='text-white text-xl'>
        <p> {quantity} grams of weed.</p>
      
         </div>

      <div className='space-x-2'>
      <Button color='success' onClick={() => setQuantity(quantity + .5)} variant="contained">+</Button>
      <Button color='error' onClick={() => setQuantity(quantity - .5)} variant="contained">-</Button>
      </div>
    </div>
   
  )
}

export default Quant