import './App.css';
import Start from './pages/Start';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Selection from './pages/Selection';
import Amount from './pages/Amount';


const style = { 
  wrapper : `h-screen w-screen max-h-screen h-min-screen bg-[#331E28] pt-60`,
  }

  function App() {
  
  return (
    document.title = 'Weed Delivery',

 <Router>
   <div className={style.wrapper}>
     <Routes>
      <Route path='/' element={<Start />}/>
      <Route path='/select' element={<Selection />}/>
      <Route path='/amount' element={<Amount />}/>

     </Routes>
   </div>
 </Router>
   
 
  );
}



export default App;
