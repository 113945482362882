import { TextField } from '@mui/material';
import React from 'react'
import Navigation from '../components/Navigation';



function Start() {
  return (
    <div>
        <div className='flex justify-center'>
            <div className='w-20'>
                <img src='logo192.png' alt=''></img> 
              </div>
           </div>
          <div className='flex justify-center mt-20'>
            <h1 className= 'text-white text-2xl'>Did someone say weed?</h1>
          </div>
          <div className='flex justify-center mt-20 space-x-5'>
            <TextField id="filled-basic" label="Enter your address" variant="filled" color="success"/>
          </div>
          <div className=' flex justify-end'>
              <Navigation />
          </div>
              
    </div>
  )
}

export default Start