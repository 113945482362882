import React from 'react'
import Quant from '../components/Quant'

function Amount() {
  return (
    <div className='flex justify-center mt-10'>
        <div> 

            <div className='text-white text-2xl'>
                <h1>How much 🌿 are we talking?</h1>
            </div>

            <div className='mt-10'>
                <Quant />
            </div>
        
        </div>

       
    </div>
  )
}

export default Amount