import { Button, Slider } from '@mui/material'
import React from 'react'
import Moods from '../components/Moods'

function Selection() {
  return (
    <div className='flex justify-center mt-10'>
        <div>
            <div className='ml-10'>
                <h1 className= 'text-white text-2xl'>How are we feeling?</h1>
            </div>
            <div className='flex space-x-10 mt-10 '>
                <Moods symbol ='⚡️' />
                <Moods symbol = '💤' />
                <Moods symbol = '⛑' />
                <Moods symbol = '🧘‍♀️' />
            </div>
            <div className='mt-14 text-white'> 
            <p>Indica</p>
                <Slider size ='large' 
            defaultValue={50}
            sx={{
                color: 'success.main',
              }}
            
            />
                       
            </div>
            <div>
                <Button href='/amount' variant="outlined">Next</Button>
            </div>
            

        </div>
    </div>
  )
}

export default Selection